import React from "react"
import styled from 'styled-components'
import { Link } from 'gatsby'
// import SEO from "../components/SEO"

const H1Tag = styled.h1`
    font-size: 22px;
    color: #000;
    letter-spacing: 2.7px;
    text-transform: uppercase;
    padding-top: 20px;

    @media screen and (min-width: 1600px) {
        font-size: 24px;
    }

    @media screen and (max-width: 1100px) {
        font-size: 20px;
    }

    @media screen and (max-width: 750px) {
        font-size: 18px;
    }
`
const LinkTag = styled(Link)`
    text-transform: uppercase;
    text-decoration: none;
    color: #000;
    margin-bottom: 5vh;

    &:after {
        margin-left: 15px;
        content: "→";
        transform-origin: 50% 50%;
        display: inline-block;
        font-family: 'KontrapunktMiki-Regular';
        font-size: 18px;
    }

    &:last-child {
        margin-bottom: 0;
    }
`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);    
    padding: 0 4vw;
    width: calc( 100% - 8vw );
`

const H1404Tag = styled.h1`
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 10px;
`

const PTag = styled.p`
    font-weight: 300;
    margin-bottom: 5vh;
`

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
`

const NotFoundPage = () => (
    <MainContainer>
        {/* <SEO title="404: Not found" /> */}
        <H1Tag>Andrea Blahova</H1Tag>
        <ContentContainer>
            <H1404Tag>404 - Page Not Found</H1404Tag>
            <PTag>Go back to one of these pages:</PTag>
            <LinkTag to='/'>Home</LinkTag>
            <LinkTag to='/projects/'>Projects</LinkTag>
            <LinkTag to='/events/'>Events</LinkTag>
            <LinkTag to='/diary/'>Diary</LinkTag>
            <LinkTag to='/about/'>About</LinkTag>
        </ContentContainer>
    </MainContainer>
)

export default NotFoundPage
